.wrapper {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;

	background-color: transparent;
	height: 100vh;
	width: 100vw;
	background-color: lightgrey;
}