* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	height: 100%;
}

input,
button,
textarea,
select {
	margin: 0;
	font: inherit;
}

img {
	display: block;
	max-width: 100%;
	height: auto;
}

button,
a {
	cursor: pointer !important;
}



ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

#team-swiper {
	.swiper-slide {
		opacity: 0.8;
		transform: scale(0.8) !important;
		filter: grayscale(1) blur(4px);
		// filter: blur(3px);
		transition: all 400ms linear;

		&.swiper-slide-active {
			filter: grayscale(0);
			opacity: 1;
			transform: scale(1) !important;
		}
	}

	.swiper-button-prev {
		color: var(--white) !important;
	}

	.swiper-button-next {
		color: var(--white) !important;
	}
}

#photographers-swiper{
		a[data-value="thumb"]{
			display: block;
			position: relative;
			padding-bottom: 133%;
			overflow: hidden;
			border-radius: 12px;
			text-decoration: none;
			color: var(--white);
			transition: all var(--transition);
			img{
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}

			div[data-value="data"]{
				position: absolute;
				top: 88%;
				left: 0;
				right: 0;
				bottom: 0;
				padding: 10px;
				background-color: rgba(0, 0, 0, 0.1);
				transition: all var(--transition);

				p{
					margin-bottom: 10px;
				}

				span{
					text-align: end;
					font-size: 14px;
					opacity: 0;
					color: var(--white);
					display: block;
					transition: all var(--transition);

				}
			}
			&:hover{
				div[data-value="data"]{
					top: 80%;
					background-color: rgba(0, 0, 0, 0.3);

					span{
						opacity: 1;
					}
				}
			}
		}

	.swiper-button-prev {
		color: var(--white) !important;
	}

	.swiper-button-next {
		color: var(--white) !important;
	}
}

#progress-swiper {
	width: 100% !important;

	.swiper-button-prev,
	.swiper-button-next {
		top: 88% !important;
		height: 20px !important;
		color: var(--white) !important;
	}

	.swiper-button-prev {
		left: 17%;

		@media screen and (max-width: 767px) {
			left: 0;
		}
	}

	.swiper-button-next {
		right: 17%;

		@media screen and (max-width: 767px) {
			right: 0;
		}
	}

	.swiper-pagination {
		position: relative !important;
		transform: translateY(-40px);
		max-width: 50% !important;
		width: 100% !important;
		display: flex;
		margin: 0 auto !important;

		.swiper-pagination-bullet {
			height: 3px !important;
			width: calc(100% / 5) !important;
			background-color: white !important;
			border-radius: 0 !important;
			opacity: 1;

			&.swiper-pagination-bullet-active {
				background-color: var(--primary-text-color) !important;
			}
		}
	}
}

#reviews {
	.swiper-button-prev {
		color: var(--white) !important;
		left: 100px !important;

		@media screen and (max-width: 767px) {
			left: 10px !important;

		}
	}

	.swiper-button-next {
		right: 100px !important;
		color: var(--white) !important;

		@media screen and (max-width: 767px) {
			right: 10px !important;

		}
	}

}